<template>
  <div class="authorization">
    <b-notification auto-close :active.sync="notification.show" :type="notification.type">
      {{ notification.text }}
    </b-notification>
    <section>
      <h1 class="title">Авторизация</h1>
      <b-field label="Логин">
          <b-input class="login-input" v-model="login"></b-input>
      </b-field>

      <b-field label="Пароль">
          <b-input class="password-input" type="password" v-model="password"></b-input>
      </b-field>

      <b-button :loading="loading" class="authenticate-button" type="is-primary" @click="authenticate">Войти</b-button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Authorization',
  data () {
    return {
      login: '',
      password: '',
      loading: false,
      notification: {
        show: false,
        type: '',
        text: ''
      }
    }
  },
  methods: {
    authenticate () {
      this.loading = true
      this.$store.dispatch('auth_request', {
        user_name: this.login,
        password: this.password,
        callback: this.breakLoading
      })
    },
    breakLoading (notification) {
      this.notification.show = true
      this.notification.type = notification.type
      this.notification.text = notification.message
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.authorization {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  section {
    width: 300px;
  }

  article.notification {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
</style>
